import { Injectable } from '@angular/core';
import { AngularFireDatabase} from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { concatMap, finalize, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { FileUpload } from '../model/file-upload.model';
import { PlaylistModel } from '../model/playlist.model';
import { UserModel } from '../model/user.model';
import { VideoModel } from '../model/video.model';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  constructor(private authService: AuthService,private db:AngularFireDatabase,private storage: AngularFireStorage) { }

  get userData(){
    return this.authService.userData;
  }

  get user(){
    return this.authService.user;
  }

  logout(){
    return this.authService.logout();
  }

  getAllPlaylists(){
    return this.db.list<PlaylistModel>('/playlists').snapshotChanges().pipe(map(changes=>{
      return changes.map(c => ({ id: c.payload.key, ...c.payload.val() } as PlaylistModel));
    }));
  }

  getUserDetails(email: string){
    return this.db.list<{email:string; playlists:{id:string; expired:Date}[]}>('users', ref => ref.orderByChild('email').equalTo(email)).valueChanges().pipe(map(userDetails=>{
      if(!userDetails || userDetails.length === 0){
        return null;
      }
      if(userDetails.length> 0){
        userDetails[0].playlists.forEach(playlist=>{playlist.expired = new Date(playlist.expired)});
        return userDetails[0];
      }

      return null;

    }));
  }

  getPlaylist(id: string, expired?:Date) {
    return this.db.object<PlaylistModel>('/playlists/' + id).snapshotChanges().pipe(map(changes=>{
      if(changes.payload.val()){
        return { id: changes.payload.key, expired: expired, ...changes.payload.val() } as PlaylistModel
      } else {
        return null;
      }
    }));
  }

  getVideo(idPlaylist: string, id: string){
      return this.db.object<VideoModel>("/playlists/" + idPlaylist + "/videos/" + id).valueChanges()
    }

  savePlaylist(playlist:PlaylistModel){
    return this.db.list<PlaylistModel>("/playlists").push(playlist);
  }

  storeVideo(fileUpload: FileUpload, playlistName:string){
    const filePath = `${playlistName}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          //this.saveFileData(fileUpload);
        });
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }

  saveUser(user: UserModel) {
    return this.db.list<UserModel>("/users").push(user);
  }

  updateUser(id:string, user: UserModel) {
    return this.db.list<UserModel>("/users").update(id, user);
  }

  getAllUsers() {
    return this.db.list<UserModel>("/users").snapshotChanges().pipe(map(changes=>{
      return changes.map(c => ({ id: c.payload.key, ...c.payload.val() } as UserModel));
    }));
  }
}

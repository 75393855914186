import { VideoModel } from "./video.model";

export class PlaylistModel {
    id: string="";
    name: string = "";
    videos: VideoModel[] = [];
    expired?:Date;

    valid:boolean=false;

    constructor(){}
}
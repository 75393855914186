import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlayerComponent } from './video/player/player.component';
import { VideoComponent } from './video/player/video/video.component';
import { PlaylistComponent } from './video/player/playlist/playlist.component';
import { HeaderComponent } from './header/header.component';
import { VideoStartComponent } from './video/player/video/video-start/video-start.component';
import { VideoItemComponent } from './video/player/playlist/video-item/video-item.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FooterComponent } from './footer/footer.component';
import { AuthComponent } from './auth/auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlaylistsComponent } from './video/playlists/playlists.component';
import { PlaylistItemComponent } from './video/playlists/playlist-item/playlist-item.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { AdminComponent } from './admin/admin.component';
import { AdminNewPlaylistComponent } from './admin/new-playlist/new-playlist.component';
import { AdminUsersComponent } from './admin/users/users.component';
import { AdminPlaylistsComponent } from './admin/playlists/playlists.component';
import { AdminNewUserComponent } from './admin/new-user/new-user.component';
import { AdminUserItemComponent } from './admin/users/user-item/user-item.component';
import { AdminUserComponent } from './admin/users/user/user.component';
import { LogOnComponent } from './auth/log-on/log-on.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import { ErrorGenericComponent } from './error-generic/error-generic.component';
import { environment } from 'src/environments/environment';
import { UploadFileComponent } from './admin/new-playlist/upload-file/upload-file.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordSendedComponent } from './auth/reset-password-sended/reset-password-sended.component';

// const firebaseConfig = {
//   apiKey: "AIzaSyDBFUkUMggIQ0Elvcd3RdU-4MEQ3gC0IGg",
//   authDomain: "sottotesi-650e9.firebaseapp.com",
//   databaseURL: "https://sottotesi-650e9.firebaseio.com",
//   projectId: "sottotesi-650e9",
//   storageBucket: "sottotesi-650e9.appspot.com",
//   messagingSenderId: "803741795968",
//   appId: "1:803741795968:web:3c03f003ceb5e476f90865",
//   measurementId: "G-6L5ZM5KB5Q"
// };

@NgModule({
  declarations: [
    AppComponent,
    PlayerComponent,
    VideoComponent,
    PlaylistComponent,
    HeaderComponent,
    VideoStartComponent,
    VideoItemComponent,
    PageNotFoundComponent,
    FooterComponent,
    AuthComponent,
    PlaylistsComponent,
    PlaylistItemComponent,
    AdminComponent,
    AdminNewPlaylistComponent,
    AdminUsersComponent,
    AdminPlaylistsComponent,
    AdminNewUserComponent,
    AdminUserItemComponent,
    AdminUserComponent,
    LogOnComponent,
    EmailVerificationComponent,
    ErrorGenericComponent,
    UploadFileComponent,
    ForgotPasswordComponent,
    ResetPasswordSendedComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,     
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule
  ],
  providers: [
    {provide:HTTP_INTERCEPTORS, useClass:AuthInterceptorService, multi: true},
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

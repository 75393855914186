import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileUpload } from 'src/app/shared/model/file-upload.model';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage?: number;
  uploadCompleted = false;

  @Input() src!: string;
  @Input() form!: FormGroup;
  @Input() index!: number;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
  }

  selectFile(event:any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    const file = this.selectedFiles?.item(0);
    this.selectedFiles = undefined;
    if(!!file){
      this.currentFileUpload = new FileUpload(file);
      this.adminService.storeVideo(this.currentFileUpload, "/latino").subscribe(
        percentage => {
          if(percentage){
            this.percentage = Math.round(percentage);
            
          }
        },
        error => {
          console.log(error);
        },
        ()=>{
          this.uploadCompleted = true;
          
        }
      );
    }
    
  }
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-generic',
  templateUrl: './error-generic.component.html',
  styleUrls: ['./error-generic.component.css']
})
export class ErrorGenericComponent implements OnInit {

  title="Errore";
  message="Si è verificato un errore. Riprova o contattaci";
  constructor(private router:Router) { 
    const navigation = this.router.getCurrentNavigation();
    if(navigation?.extras.state){
      this.title = navigation.extras.state.title;
      this.message = navigation.extras.state.message;
    }
  }

  ngOnInit(): void {
  }

}

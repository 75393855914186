import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

function ConfirmedValidator(controlName: string, matchingControlName: string){

  return (formGroup: FormGroup) => {

      const control = formGroup.controls[controlName];

      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {

          return;

      }

      if (control.value !== matchingControl.value) {

          matchingControl.setErrors({ confirmedValidator: true });

      } else {

          matchingControl.setErrors(null);

      }

  }

}

@Component({
  selector: 'app-log-on',
  templateUrl: './log-on.component.html',
  styleUrls: ['./log-on.component.css']
})
export class LogOnComponent implements OnInit {

  hasError = false;
  alreadyUsedError =false;
  error = "Si è verificato un errore!";

  form = new FormGroup({
    email: new FormControl('', {validators:[Validators.required, Validators.email], updateOn: "blur"}),
    password: new FormControl('', {validators:[Validators.required, Validators.minLength(6)], updateOn: "blur"}),
    confirmPassword: new FormControl('', {validators:[Validators.required, Validators.minLength(6)], updateOn: "blur"}) 
  },
  CustomValidators.mustMatchValidator('password','confirmPassword')
  );

  constructor(private authService:AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  get email(){
    return this.form.get('email');
  }

  get password(){
    return this.form.get('password');
  }

  get confirmPassword(){
    return this.form.get('confirmPassword');
  }
  
  onSubmit(){
    const email = this.form.value.email;
    const password = this.form.value.password;
    //this.form.reset();
    this.authService.logon(email, password).then(()=>{
      this.authService.user.pipe(take(1)).subscribe(user=>{
        if(environment.enableEmailVerification) {
          user.sendEmailVerification();
        }
        this.router.navigate(['/verifica-email']);
      });
    }).catch(error=> {
        switch (error.code) {
            case "auth/email-already-in-use":
                this.error = "Email già in uso. Accedi con il tuo account";
                this.alreadyUsedError = true;
                break;            
            default:
                this.hasError = true; 
                break;
        }          
    });
  }
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from '../shared/service/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private commonService: CommonService, private router:Router) { }

  isLogged = true;
  isAdmin = false;

  ngOnInit(): void {
    this.commonService.user.subscribe(user =>{
      this.isLogged = !user;
      this.isAdmin = user?.email === environment.adminEmail;
  })
  }

  logout(){
    this.commonService.logout();
    
  }

}

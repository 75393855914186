import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from "@angular/router";
import { Observable } from "rxjs";


// export interface AuthResponseData{
// idToken: string;
// email: string;
// refreshToken: string; 
// expiresIn: string;
// localId: string;
// registered: boolean;
// }

@Injectable({providedIn:'root'})
export class AuthService {
    
    //user = new BehaviorSubject<User|null>(null);

    userData: any;

    user: Observable<any>;
    
    constructor(private auth: AngularFireAuth, private router:Router){
      this.user = this.auth.user;
        this.auth.authState.subscribe(user => {
            if (user) {
              this.userData = user;
              localStorage.setItem('user', JSON.stringify(this.userData));
            } else {
              this.userData = null;
              localStorage.setItem('user', JSON.stringify(null));
            }
          })
    }
    
    get authState(){
        return this.auth.authState;
    }

    logon(email: string, password: string){
      return this.auth.createUserWithEmailAndPassword(email, password);
    } 
    

    login(email: string, password: string){
        return this.auth.signInWithEmailAndPassword(email,password);
    }

    logout(){
        return this.auth.signOut().then(() => {
            localStorage.removeItem('user');
            this.router.navigate(['/login']);

          })
    } 
    
    resetPassword(email: string){
      return this.auth.sendPasswordResetEmail(email);
    }
}
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({providedIn:'root'})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router){}

    canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | UrlTree | Promise <boolean | UrlTree> | Observable <boolean | UrlTree>{
        return this.authService.user.pipe(take(1),map(user => {
            if(user) {
                if(environment.enableEmailVerification && !user.emailVerified){
                    return this.router.createUrlTree(['/verifica-email']);
                }
                if(router.url.includes('admin')){
                    if(user.email && user.email === environment.adminEmail){
                        return true;
                    } else {
                        this.authService.logout();
                        return this.router.createUrlTree(['/not-found']);
                    }
                } else {
                    return true;
                }
                
            }
            return this.router.createUrlTree(['/login']);
        }),
        );
    }
}
export const environment = {
  production: true,
  adminEmail:"sottotesi.it@gmail.com",
  enableEmailVerification: true,
  firebaseConfig: {
    apiKey: "AIzaSyDthMTN-bBLol1PWXj2zChGDP1szUNOUJI",
    authDomain: "corsi-sottotesi.firebaseapp.com",
    databaseURL: "https://corsi-sottotesi-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "corsi-sottotesi",
    storageBucket: "corsi-sottotesi.appspot.com",
    messagingSenderId: "565803819822",
    appId: "1:565803819822:web:3367c89d135d8c491510bf",
    measurementId: "G-DLH16GC4EQ"
  }
};

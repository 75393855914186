import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { PlaylistModel } from 'src/app/shared/model/playlist.model';
import { CommonService } from '../../../shared/service/common.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css']
})
export class PlaylistComponent implements OnInit {

  playlist: PlaylistModel = new PlaylistModel();

  constructor(private commonService: CommonService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.pipe(concatMap(params => {return this.commonService.getPlaylist(params['idPlaylist'])}))
    .subscribe(playlist =>{
            if(playlist){
              this.playlist = playlist;
            } else {
              this.router.navigate(["/not-found"]);
            }
          });    
  }
}

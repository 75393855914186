import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PlaylistModel } from 'src/app/shared/model/playlist.model';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.css']
})
export class PlaylistsComponent implements OnInit {

  playlists: PlaylistModel[] = [];
  userData:any;

  constructor(private commonService: CommonService, private router: Router) {
    this.userData = this.commonService.userData;
   }

  ngOnInit(): void {
    if(this.userData){
      this.commonService.getUserDetails((<string>this.userData.email).toLowerCase()).pipe(catchError(error=>{
        this.router.navigate(['/errore'],{state:{title:"Utente non trovato", message:"Riprova, se continua a verificarsi contattaci!"}});
        return of(null);
      })).subscribe(userDetails=>{
        if (userDetails){
          if(userDetails.playlists.length === 0) {
            this.router.navigate(['/errore'],{state:{title:"Nessun corso", message:"Risulta che non hai acquistato nessuno corso. Se l'hai acquistato, contattaci!"}});
            return;
          }

          if (userDetails.playlists.length === 1 && userDetails.playlists[0].expired > new Date()) {
            this.router.navigate(['/corso', userDetails.playlists[0].id]);
          } else {
            userDetails.playlists.forEach(playlist=>{
              this.commonService.getPlaylist(playlist.id,playlist.expired).subscribe(playlist => {
                if (playlist && playlist.expired){
                  playlist.valid = playlist.expired > new Date();
                  this.playlists.push(playlist);
                }
              });
            });
          }
        } else {
          this.router.navigate(['/errore'],{state:{title:"Utente non trovato", message:"Risulta che non hai acquistato nessuno corso. Contattaci per maggiori informazioni!"}});
        }
        
      });
    } else {
      this.router.navigate(['/login']);
    }    
  }
}

import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor{

    constructor(private authService: AuthService){}

    intercept(req:HttpRequest<any>, next: HttpHandler){
        return next.handle(req);
        if(this.authService.userData){
            const modifedReq = req.clone(
                {params: new HttpParams().set("auth",this.authService.userData.uid)}
            );
            return next.handle(modifedReq);
        }
        return next.handle(req);

        // return this.authService.getAuthState().pipe(take(1), exhaustMap(user=>{
        //     if(user){
        //         const modifedReq = req.clone(
        //             {params: new HttpParams().set("auth",user.uid)}
        //         );
        //         return next.handle(modifedReq);
        //     }
            
        //     return next.handle(req);
        // }));
    }
}
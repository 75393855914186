import { Component, Input, OnInit } from '@angular/core';
import { VideoModel } from '../../../../shared/model/video.model';

@Component({
  selector: 'app-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.css']
})
export class VideoItemComponent implements OnInit {

  @Input() video!:VideoModel;
  @Input() id!:number;

  constructor() { }

  ngOnInit(): void {
  }

}

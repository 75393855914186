import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VideoStartComponent } from './video/player/video/video-start/video-start.component';
import { VideoComponent } from './video/player/video/video.component';
import { PlayerComponent } from './video/player/player.component';
import { PlaylistsComponent } from './video/playlists/playlists.component';
import { AuthGuard } from './auth/auth.guard';
import { AdminComponent } from './admin/admin.component';
import { AdminNewPlaylistComponent } from './admin/new-playlist/new-playlist.component';
import { AdminUsersComponent } from './admin/users/users.component';
import { AdminPlaylistsComponent } from './admin/playlists/playlists.component';
import { AdminNewUserComponent } from './admin/new-user/new-user.component';
import { AdminUserComponent } from './admin/users/user/user.component';
import { LogOnComponent } from './auth/log-on/log-on.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import { ErrorGenericComponent } from './error-generic/error-generic.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordSendedComponent } from './auth/reset-password-sended/reset-password-sended.component';

const routes: Routes = [
  {path:'', redirectTo:'/corsi', pathMatch:'full'},
  
  {path:'corsi',component:PlaylistsComponent, 
canActivate:[AuthGuard]},
  {path:'admin', component:AdminComponent, children:[
    {path:'corsi', component: AdminPlaylistsComponent},
    {path:'nuovo-corso', component: AdminNewPlaylistComponent},
    {path:'utenti', component:AdminUsersComponent, children:[
      {path:':id', component:AdminUserComponent}
    ]},
    {path:'nuovo-utente', component:AdminNewUserComponent}
  ],
canActivate:[AuthGuard] }, //DA CAMBIARE
  {path:'corso/:idPlaylist',component:PlayerComponent, children:[
    {path:'', component: VideoStartComponent},
    {path:':id', component:VideoComponent}
  ], 
  canActivate:[AuthGuard]},
  {path:'login', component:AuthComponent},
  {path:'registrati', component:LogOnComponent},
  {path:'verifica-email', component:EmailVerificationComponent},
  {path:'recupero-password', component:ForgotPasswordComponent},
  {path:'recupero-inviato', component:ResetPasswordSendedComponent},
  {path:'not-found', component:PageNotFoundComponent},
  {path:'errore', component:ErrorGenericComponent},
  {path:'**', redirectTo:'/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PlaylistModel } from 'src/app/shared/model/playlist.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-new-playlist',
  templateUrl: './new-playlist.component.html',
  styleUrls: ['./new-playlist.component.css']
})
export class AdminNewPlaylistComponent implements OnInit {

  playlist = new PlaylistModel();

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    descrizione: new FormControl('', Validators.required),
    videos: new FormArray([])
  });

  videos= this.form.get('videos') as FormArray;

  constructor(private router: Router, private adminService: AdminService) { }

  ngOnInit(): void {
    
  }

  onSubmit(){
    this.adminService.savePlaylist(this.form.value);
    this.router.navigate(['/admin']);
  }

  // removeVideo(index: number){
  //   debugger;
  //   this.videos.removeAt(index);
  // }

  addVideo(){
    this.videos.push(new FormGroup({
      name: new FormControl('', Validators.required),
      src: new FormControl('', Validators.required),
      desc: new FormControl()}))
  }
  
}

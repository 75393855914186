import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VideoModel } from 'src/app/shared/model/video.model';
import { CommonService } from '../../../shared/service/common.service';
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  video: VideoModel;

  constructor(private commonService:CommonService, private route: ActivatedRoute, private router: Router) { 
    this.video = new VideoModel();
  }


  ngOnInit(): void {
    let idPlaylist = ""
    if(this.route.parent){
      idPlaylist = this.route.parent.snapshot.params["idPlaylist"];
    } else {
      this.router.navigate(['/not-found']);
    }
    this.route.params.pipe(switchMap(params => {return this.commonService.getVideo(idPlaylist, params['id'])})).subscribe(
      video =>{
            if(video){
              this.video = video;
            } else {
              this.router.navigate(['/not-found']);
            }
          });
  }

}

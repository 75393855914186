import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Component({
    selector:'app-auth',
    templateUrl:'./auth.component.html'
})
export class AuthComponent implements OnInit{

    hasError = false;
    error = "Si è verificato un errore!";

    form = new FormGroup({
        email: new FormControl('', {validators:[Validators.required, Validators.email], updateOn: "blur"}),
        password: new FormControl('', {validators:[Validators.required, Validators.minLength(6)], updateOn: "blur"})}
      );
    
    get email(){
    return this.form.get('email');
    }

    get password(){
    return this.form.get('password');
    }
      
    constructor(private authService: AuthService, private router: Router){}

    ngOnInit(){
        this.authService.authState.pipe(take(1)).subscribe(user =>{
            if(user){
                if(user.email === environment.adminEmail){
                    this.router.navigate(['/admin']);
                } else{
                    this.router.navigate(['/corsi']);
                }
            } 
        });
    }

    onSubmit(){
        const email = this.form.value.email;
        const password = this.form.value.password;
        //this.form.reset();
        this.authService.login(email, password).then((userCredential)=>{
            if(environment.enableEmailVerification && !userCredential.user?.emailVerified){
                this.router.navigate(['/verifica-email']);
            } else {
                if(userCredential.user?.email === environment.adminEmail){
                    this.router.navigate(['/admin']);
                } else{
                    this.router.navigate(['/corsi']);
                }
            }                
        }).catch(error=> {
            this.hasError = true;  
            switch (error.code) {
                case "auth/user-not-found":
                case "auth/wrong-password":
                    this.error="Email/Password non validi!"
                    break;            
                default:
                    break;
            }          
        });
    }

}
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  hasError = false;
  error = "Si è verificato un errore!";

  get email(){
    return this.form.get('email');
    }

  constructor(private authService: AuthService, private router: Router) { }
  form = new FormGroup({
    email: new FormControl('', {validators:[Validators.required, Validators.email], updateOn: "blur"})
  });

  
  ngOnInit(): void {
  }

  onSubmit(){
    const email = this.form.value.email;
    
    this.authService.resetPassword(email).then(()=>{
        this.router.navigate(['/recupero-inviato']);
      }
      ).catch(error=> {
        this.hasError = true; 
        switch (error.code) {
          case "auth/user-not-found":
            this.error = "Email non registrata!";
            break;
          default:
              break;
        }          
    });
  }

}

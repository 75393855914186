export class UserModel {
    id:string="";
    name:string=""; 
    email: string=""; 
    playlists: Playlist[]=[];
}

class Playlist{
    id: string= ""; 
    expired: Date = new Date();
}
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaylistModel } from 'src/app/shared/model/playlist.model';
import { UserModel } from 'src/app/shared/model/user.model';
import { AdminService } from '../../admin.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class AdminUserComponent implements OnInit {

  user:UserModel= new UserModel();

  //MOCK
  blockExpired= false;

  playlistsData: PlaylistModel[]=[];

  form = new FormGroup({
    name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required]),
    playlists: new FormArray([])
  });
  playlistsControl= this.form.get('playlists') as FormArray;

  constructor(private route: ActivatedRoute, private router: Router, private adminService:AdminService) { }

  ngOnInit(): void {
    this.adminService.playlists.subscribe(playlists=>{
      this.playlistsData = playlists;
    });    

    this.route.params.subscribe(params=>{
      this.playlistsControl.clear();
      this.adminService.users.subscribe(usersModel=>{
        const user = usersModel.find(user=>{
          return user.id===params['id'];
        });        
      if(user){
        this.user = user;
        this.form.get('name')?.setValue(this.user.name);
        this.form.get('email')?.setValue(this.user.email);

        this.adminService.playlists.subscribe(playlists=>{
          this.playlistsData = playlists;
          playlists.forEach(playlist=>{
            this.playlistsControl.push(new FormGroup({
              checked:new FormControl({value:this._playlistChecked(playlist), disabled: this.blockExpired}), 
              expired: new FormControl({value: this._getUserPlaylist(playlist.id)?.expired, disabled: this.blockExpired})
            }));
          });
        });


        
      }
      });
    });
  }
  private _getUserPlaylist(id: string){
    return this.user.playlists?.find(el=>{
      return el.id === id;
    });
  }

  private _playlistChecked(playlist: PlaylistModel){
    const userPlaylist = this._getUserPlaylist(playlist.id);
    if(userPlaylist)
      return new Date() < new Date(userPlaylist.expired);
    else 
      return false;

  }

  onSubmit(){
    const playlists = this.form.value.playlists as {checked:boolean,expired:Date}[];
    let playlistsSelected:{id:string, expired?:Date}[] = [];
    playlists.forEach((element,index)=>{
      if(element.checked){
        playlistsSelected.push({id:this.playlistsData[index].id, expired: element.expired});
      }
    });
    const user = {
      name:this.form.value.name,
      email:this.form.value.email,
      playlists: playlistsSelected
    } as UserModel;

    this.adminService.updateUser(this.user.id, user);
    this.router.navigate(['/admin']);
  }
}

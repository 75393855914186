import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PlaylistModel } from 'src/app/shared/model/playlist.model';
import { UserModel } from 'src/app/shared/model/user.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class AdminNewUserComponent implements OnInit {

  playlistsData: PlaylistModel[]=[];

  form = new FormGroup({
    name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required]),
    playlists: new FormArray([]),
    expired: new FormControl('',[Validators.required])
  });
  playlists= this.form.get('playlists') as FormArray;

  constructor(private router:Router, private adminService:AdminService) { }

  ngOnInit(): void {
    this.adminService.playlists.subscribe(playlists=>{
      this.playlistsData = playlists;
      playlists.forEach(()=>{
        this.playlists.push(new FormControl(false));
      });
    });
  }

  onSubmit(){
    const playlists = this.form.value.playlists as boolean[];
    let playlistsSelected:{id:string, expired:Date}[] = [];
    this.playlistsData.forEach((element,index)=>{
      if(playlists[index]){
        playlistsSelected.push({id:element.id, expired: this.form.value.expired});
      }
    });
    const user= {
      name:this.form.value.name,
      email:(<string>this.form.value.email).toLowerCase(),
      playlists: playlistsSelected
    } as UserModel;
    
    this.adminService.saveUser(user);
    this.router.navigate(['/admin']);
  }

}

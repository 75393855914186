import { Injectable } from "@angular/core";
import { FileUpload } from "../shared/model/file-upload.model";
import { PlaylistModel } from "../shared/model/playlist.model";
import { UserModel } from "../shared/model/user.model";
import { CommonService } from "../shared/service/common.service";

@Injectable({
    providedIn: 'root'
  })
export class AdminService{
    
    get playlists(){
        return this.commonService.getAllPlaylists();
    }

    get users(){
        return this.commonService.getAllUsers();
    }

    constructor(private commonService:CommonService){
    }
    
    
    saveUser(user: UserModel) {
        return this.commonService.saveUser(user);
    }

    updateUser(id:string, user: UserModel){
        return this.commonService.updateUser(id, user);
    }

    savePlaylist(playlist:PlaylistModel){
        return this.commonService.savePlaylist(playlist);
    }

    storeVideo(fileUpload: FileUpload, playlistName: string){
        return this.commonService.storeVideo(fileUpload, playlistName);
    }
  

}
import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/shared/model/user.model';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class AdminUsersComponent implements OnInit {

  users: UserModel[]=[]

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.adminService.users.subscribe(usersModel=>{
      this.users=usersModel;
    });
  }

}

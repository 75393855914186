import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
    public static mustMatchValidator(property1:string, property2: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const fcProp1 = control.get(property1);
            const fcProp2 = control.get(property2);
            if(fcProp1?.errors || fcProp2?.errors){
                return null;
            }
          const prop1 = fcProp1?.value;
          const prop2 = fcProp2?.value;

          return prop1===prop2 ? null : {notSame: true};
        };
      }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-start',
  templateUrl: './video-start.component.html',
  styleUrls: ['./video-start.component.css']
})
export class VideoStartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
